import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeEraseRight,
  createSpace,
  placeStartOver,
  writeHTMLText,
  placeBoldText
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = createSpace(-3, 10, -4, 9);
    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=18;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
  //  brd1.options.layer['polygon']=17;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.polygon.highlight=false;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeLogo(brd1);
    placeTitle(brd1, 'Surface Area of a Cube', '(Tap on the facets of the cube to unfold it)');
/////////////////////
    var analytics = placeEraseLeft(brd1);
    analytics.setLabel('Tap to Erase & Start Over')
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeTest(brd1);
    erase.setLabel('Tap to Test Your Answer')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    var rght = placeImage(brd1, '/assets/check.svg', 8, 6, 1.,  0);
    var wrng = placeImage(brd1, '/assets/cross.svg', 8, 6, 1.,  0);
    rght.setAttribute({visible:false});
    wrng.setAttribute({visible:false});
  //  placeMiddleText(brd1, 0, 12, ()=>'Given Number =' + NumList[i]);
    /////////////////////////////////////////////////////////
    //3D Shape
    var x1 = -2;
    var y1 = 0.;
    var shape=[];
    var face=[];
    ////////////////////////////////////////////////////////////////////////////
    function checkColorFront(){
        if(m == 0){
            rght.setAttribute({visible: true});
            wrng.setAttribute({visible:false});
        }
        else{
            wrng.setAttribute({visible:true});
            rght.setAttribute({visible:false});
        }
    }
    var eraseit =function(){
      wrng.setAttribute({visible:false});
      rght.setAttribute({visible:false});
    }
    erase.on('down', function(){checkColorFront();});
///////////////////////////////////////////
    const delX=1.15; var i=0; var j=0; var k=0; var m=6;
    var txt1 = placeBoldText(brd1, 0, 6.5, ()=>''+m+' facets left to unfold !');
    var txt2 = writeHTMLText(brd1, 6, 6.5, ()=>'<b>Surface area = '+ 1*(6-m) +' a^2 ?</b>');
    txt2.setAttribute({visible:true});;
  //  var txt5 = placeMiddleText(brd1, x1+1.5, y1+2+0.5, '2');
  //  var txt6 = placeMiddleText(brd1, x1+2.5, y1+1+0.5, '3');
    //back
    face[5]= brd1.create('regularpolygon', [[x1+delX, y1+delX], [x1+3+delX, y1+delX], 4], {hasInnerPoints:()=>i==0&&j==0&&k==0,  withLines:true, vertices:{visible:false, fixed:true}, borders:{visible:()=>i==0||k==0, strokeColor:'black', strokeWidth:2}, fillColor:'grey', fillOpacity:0.25});
    face[5].on('down', function(){txtm[7].setAttribute({visible:true}),Trects[1][3].setAttribute({visible:true, borders:{visible:true}}); m-=1; face[5].setAttribute({visible:false, borders:{visible:false}});});
// bottom
    face[4] = brd1.create('polygon', [[x1, y1], [x1+3, y1], [x1+3+delX, y1+delX], [x1+delX, y1+delX]], {hasInnerPoints:()=>i==0&&j==0&&k==0, withLines:true, vertices:{visible:false, fixed:true}, borders:{visible:()=>i==0, strokeColor:'black', strokeWidth:2}, fillColor:'grey', fillOpacity:0.25});
    face[4].on('down', function(){txtm[4].setAttribute({visible:true}),Trects[1][0].setAttribute({visible:true, borders:{visible:true}});
    m-=1;
    face[4].setAttribute({visible:false, borders:{visible:false}});});
// side
    face[3] = brd1.create('polygon', [[x1, y1], [x1+delX, y1+delX], [x1+delX, y1+3+delX], [x1, y1+3]], {hasInnerPoints:()=>i==0&&j==0&&k==0,withLines:true, vertices:{visible:false, fixed:true}, borders:{visible:()=>i==0||j==0, strokeColor:'black', strokeWidth:2}, fillColor:'grey', fillOpacity:0.25});
    face[3].on('down', function(){txtm[1].setAttribute({visible:true}),Trects[0][1].setAttribute({visible:true, borders:{visible:true}});
    m-=1;
    face[3].setAttribute({visible:false, borders:{visible:false}});});
//top
    face[2] = brd1.create('polygon', [[x1+3, y1], [x1+3+delX, y1+delX], [x1+3+delX, y1+3+delX], [x1+3, y1+3] ], {hasInnerPoints:true,withLines:true, vertices:{visible:false, fixed:true}, borders:{strokeColor: 'black', strokeWidth:2}, fillColor: 'white', fillOpacity:1.0});
    face[2].on('over', function(){k=1});
    face[2].on('out', function(){k=0});
    face[2].on('down', function(){txtm[9].setAttribute({visible:true}),Trects[2][1].setAttribute({visible:true, borders:{visible:true}});k=0; m-=1; face[2].setAttribute({visible:false, borders:{visible:false}});});
//side
    face[1] = brd1.create('polygon', [[x1, y1+3], [x1+3, y1+3], [x1+3+delX, y1+3+delX], [x1+delX, y1+3+delX]], {hasInnerPoints:true, withLines:true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    face[1].on('over', function(){j=1});
    face[1].on('out', function(){j=0});
    face[1].on('down', function(){txtm[6].setAttribute({visible:true}),Trects[1][2].setAttribute({visible:true, borders:{visible:true}});j=0; m-=1; face[1].setAttribute({visible:false, borders:{visible:false}});});
//front
    face[0]= brd1.create('regularpolygon', [[x1, y1], [x1+3, y1], 4], {hasInnerPoints:true, withLines:true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    face[0].on('over', function(){i=1});
    face[0].on('out', function(){i=0});
    face[0].on('down', function(){txtm[5].setAttribute({visible:true}),Trects[1][1].setAttribute({visible:true, borders:{visible:true}});i=0;
    m -=1; face[0].setAttribute({visible:false, borders:{visible:false}});});
// face
   var width=2;
   var num_width=2;
   var Trects=[];
   var txtm=[];
    for (let x=0; x<3; x++)
    {
    let rectx=[];
    for (let y=0; y<4; y++)
    {
        let v = true;
        let v2 = false;
        var Trect = brd1.create('regularpolygon', [[x*width-1.*num_width*width+7, y*width-2], [x*width-1.*num_width*width+9, y*width-2], 4], {hasInnerPoints:true, visible:false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor:'grey', strokeWidth:1}, fillColor: 'green', fillOpacity:0.75});
        var txt = writeHTMLText(brd1, x*width-(num_width-0.5)*width+7, (y+0.5)*width-2, 'a^2');
        txt.setAttribute({visible:false});
        rectx.push(Trect);
        txtm.push(txt);
    }
    Trects.push(rectx);
}
//////////////////////////////////////////////////////////////////////
  function clearVisibility(){
      face.forEach((item, im) => {
        item.setAttribute({visible:true, borders:{visible:true}});
      });
      txtm.forEach((item, im) => {
        item.setAttribute({visible:false});
      });
      Trects.forEach((item, imm) => {
        item.forEach((imm)=>{imm.setAttribute({visible:false, borders:{visible:false}})});
      });
      rght.setAttribute({visible:false});
      wrng.setAttribute({visible:false});}
    analytics.on('down', function(){i=0;j=0;k=0;m=6;
      clearVisibility();
    });
///////////////////////////////////////////////////////////////////////
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
